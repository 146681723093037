$(function(){
  let $passwordShowIcon = $('#password-show-icon');
  let $passwordHideIcon = $('#password-hide-icon');
  let $passwordForm = $('#user_password');

  $(document).on('click', '#password-show-icon', function(){
    //パスワードを表示する
    $passwordForm.attr('type', 'text');
    $passwordShowIcon.hide();
    $passwordHideIcon.show();
  });

  $(document).on('click', '#password-hide-icon', function(){
    //パスワードを非表示にする
    $passwordForm.attr('type', 'password');
    $passwordHideIcon.hide();
    $passwordShowIcon.show();
  });
});
